var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wap-page" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        _vm.type === "enterprise"
          ? _c("i", {
              staticClass: "me-icon-company",
              attrs: { slot: "center" },
              slot: "center"
            })
          : _vm._e(),
        _c("i", {
          staticClass: "me-icon-share",
          attrs: { slot: "right-one" },
          on: { click: _vm.handlerChangeStatus },
          slot: "right-one"
        })
      ]),
      _c(
        "div",
        { ref: "detailContent", staticClass: "me-detail-content" },
        [
          _c("Author", {
            staticClass: "me-detail-content__author",
            attrs: {
              item: {
                name: _vm.info.name,
                id: _vm.info.provider_id,
                time: _vm.info.publish_time,
                avatar: _vm.info.profile_photo
              }
            }
          }),
          _c("h2", { staticClass: "me-detail-content__title" }, [
            _vm._v(_vm._s(_vm.info.i_can_do))
          ]),
          _vm.info.price
            ? _c("span", { staticClass: "me-detail-content__price" }, [
                _vm._v("\n      " + _vm._s(_vm.info.price) + "\n    ")
              ])
            : _vm._e(),
          _vm.tags.length
            ? _c(
                "div",
                { staticClass: "me-detail-content__tags" },
                _vm._l(_vm.tags, function(tag, index) {
                  return _c(
                    "van-tag",
                    {
                      key: "tag" + index,
                      attrs: { plain: "", type: "success", round: "" }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "me-detail-content__swiper" },
            [
              _c(
                "div",
                { staticClass: "me-detail-content__swiper--imgs" },
                [
                  _vm._l(_vm.images, function(image, index) {
                    return [
                      image
                        ? _c("img", {
                            key: "swiper" + index,
                            attrs: { src: image },
                            on: {
                              click: function($event) {
                                return _vm.handlerShowImg(index)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  }),
                  _vm._l(_vm.videos, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "video" + index,
                        staticClass: "me-detail-content__swiper--video",
                        on: {
                          click: function($event) {
                            return _vm.handlerPlayVideo(item.path, item.name)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "me-icon-playVideo" }),
                        _c("video", [
                          _c("source", { attrs: { src: item.path } })
                        ])
                      ]
                    )
                  })
                ],
                2
              ),
              _c("van-image-preview", {
                staticClass: "me-detail-content__imgpreview",
                attrs: { images: _vm.images, startPosition: _vm.curImgIndex },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.showImg,
                  callback: function($$v) {
                    _vm.showImg = $$v
                  },
                  expression: "showImg"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              class: {
                "me-detail-content__desc": true,
                more: _vm.isShowMore === 2
              }
            },
            [
              _c(
                "div",
                { ref: "textContent", staticClass: "content" },
                _vm._l(_vm.info.detailArr, function(item, index) {
                  return _c("DisplayComponent", {
                    key: index,
                    attrs: { item: item }
                  })
                }),
                1
              )
            ]
          ),
          _vm.files.length
            ? _c(
                "ul",
                { staticClass: "me-detail-content__files" },
                _vm._l(_vm.files, function(file, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.downloadFile(file.path, file.name)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.formartfileIcon(
                            file.name.slice(
                              file.name.indexOf(".") + 1,
                              file.name.length
                            )
                          ),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(file.name))])
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c("Zan", {
            attrs: {
              id: _vm.id,
              count: _vm.info.like_count,
              likeStatus: _vm.info.like_id ? true : false,
              type: _vm.handlerTransType()
            }
          }),
          _c("Comments", {
            ref: "comments",
            attrs: {
              id: _vm.id,
              count: _vm.info.reviewer_count,
              type: _vm.type
            },
            on: {
              handlerReplay: _vm.handlerEmitReplay,
              replayChild: _vm.handlerEmitReplayChild
            }
          }),
          _vm.type !== "service"
            ? _c("Recommend", { attrs: { id: _vm.id, type: _vm.type } })
            : _vm._e(),
          _vm.type == "service" && _vm.info.gigId
            ? _c("PostRecommend", { attrs: { postInfo: _vm.info } })
            : _vm._e()
        ],
        1
      ),
      !_vm.showReplay
        ? _c("div", { staticClass: "chatBtn" }, [
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    return _vm.jumpChatWithPage(_vm.info)
                  }
                }
              },
              [_c("i", { staticClass: "me-icon-chat-dot" })]
            )
          ])
        : _vm._e(),
      _c("myShare", {
        attrs: { show: _vm.shareStatus, data: _vm.shareData },
        on: { close: _vm.handlerChangeStatus }
      }),
      _vm.showReplay
        ? _c(
            "div",
            { staticClass: "replayInput" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.replay,
                    expression: "replay"
                  },
                  {
                    name: "focus",
                    rawName: "v-focus",
                    value: _vm.showReplay,
                    expression: "showReplay"
                  }
                ],
                ref: "replayInput",
                attrs: { id: "replayInput" },
                domProps: { value: _vm.replay },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.replay = $event.target.value
                  }
                }
              }),
              _c("van-button", {
                attrs: { type: "primary", icon: "success" },
                on: {
                  click: function($event) {
                    _vm.isReplayChild
                      ? _vm.handlerReplayChild()
                      : _vm.handlerReplayFunc()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }