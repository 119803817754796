import { render, staticRenderFns } from "./postList.vue?vue&type=template&id=36712e4f&scoped=true&"
import script from "./postList.vue?vue&type=script&lang=js&"
export * from "./postList.vue?vue&type=script&lang=js&"
import style0 from "./postList.vue?vue&type=style&index=0&id=36712e4f&lang=stylus&scoped=true&"
import style1 from "./postList.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36712e4f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36712e4f')) {
      api.createRecord('36712e4f', component.options)
    } else {
      api.reload('36712e4f', component.options)
    }
    module.hot.accept("./postList.vue?vue&type=template&id=36712e4f&scoped=true&", function () {
      api.rerender('36712e4f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/business/newVersion/common/recommendList/postList.vue"
export default component.exports