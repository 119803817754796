<template>
  <div class="recommendPostList">
    <van-list
      v-model="postLoading"
      :finished="postFinished"
      finished-text="---end---"
      @load="onLoad"
    >
      <van-cell v-for="item in recommendedPosts" :key="item.gigId">
        <PostItem :postInfo="item" :categories="categories"></PostItem>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import { getRecommendedList,getCategories } from "@/api/webApi";
import PostItem from "../postItem";
export default {
  components: {
    PostItem
  },
  props: {
    // 需要推荐帖子的对象ID
    postInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
  },
  watch: {
    "postInfo.gigId": {
      async handler(val,old) {
        if(val && val !== old) {
          await this.handlerGetCategories();
          await this.handlerGetRecommends();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      // 推荐帖子列表
      recommendedPosts: [],
      // 当前展示的页码
      page: 1,
      // 加载loading
      postLoading: false,
      // 加载完成
      postFinished: true,
      // 类型集合
      categories: [],
    }
  },
  methods: {
    // 上拉加载
    onLoad() {
    },
    // 获取推荐帖子列表
    handlerGetRecommends() {
      return new Promise( async(resolve,reject) => {
        let params = {
          documentType: this.postInfo.documentType,
          page: this.page,
          pageSize :20,
          gigId: this.postInfo.gigId,
        };
        let result =  await getRecommendedList(this.postInfo.gigId,params);
        if(result.code === 200) {
          if(result.data.data.length !== 0) {
            this.recommendedPosts = this.recommendedPosts.concat(result.data.data);
          } else {
            this.page = this.page > 1? this.page - 1: this.page
          }
          this.$forceUpdate();
        } else {
          this.page = this.page > 1? this.page - 1: this.page
        }
        this.$nextTick(() => {
          resolve('success')
        })
      })
    },
    // 获取帖子类型
    async handlerGetCategories() {
      return new Promise( async (resolve) => {
        let params = {
          language: localStorage.getItem("langCode") || "1"
        };
        const result = await getCategories(params);
        if (result) {
          this.categories = result.data.data.map(item => {
            return {
              text: item.category_name,
              value: item.category_id
            };
          });
          resolve("success")
        }
      })
    },
  }
}
</script>
<style lang="stylus" scoped>
  .recommendPostList
    width 100%;
    overflow hidden;

</style>
<style lang="stylus">
  .recommendPostList
    .van-loading 
      height 100% !important;
      padding-top 0 !important;
      align-items center !important;
    .van-list
      .van-cell
        margin-top 10px !important;
        padding 0 16px !important;
        border-bottom 1px solid #f5f5f5 !important;
        &:after
          border-bottom none !important;

</style>