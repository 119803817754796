var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recommendPostList" },
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.postFinished, "finished-text": "---end---" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.postLoading,
            callback: function($$v) {
              _vm.postLoading = $$v
            },
            expression: "postLoading"
          }
        },
        _vm._l(_vm.recommendedPosts, function(item) {
          return _c(
            "van-cell",
            { key: item.gigId },
            [
              _c("PostItem", {
                attrs: { postInfo: item, categories: _vm.categories }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }