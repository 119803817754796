<template>
  <div class="wap-page">
    <Header>
      <i slot="left-one" @click="$router.go(-1)" class="me-icon-back"></i>
      <i v-if="type === 'enterprise'" slot="center" class="me-icon-company"></i>
      <i slot="right-one" class="me-icon-share" @click="handlerChangeStatus"></i>
    </Header>
    <div class="me-detail-content" ref="detailContent">
      <Author
        class="me-detail-content__author"
        :item="{
          name: info.name,
          id: info.provider_id,
          time: info.publish_time,
          avatar: info.profile_photo
        }"
      />
      <h2 class="me-detail-content__title">{{ info.i_can_do }}</h2>
      <span v-if="info.price" class="me-detail-content__price">
        {{
        info.price
        }}
      </span>
      <div class="me-detail-content__tags" v-if="tags.length">
        <van-tag plain type="success" round v-for="(tag, index) in tags" :key="'tag' + index">{{ tag }}</van-tag>
      </div>
      <div class="me-detail-content__swiper">
        <div class="me-detail-content__swiper--imgs">
          <template  v-for="(image, index) in images">
          <img
            v-if="image"           
            :key="'swiper' + index"
            :src="image"
            @click="handlerShowImg(index)"
          />
          </template>
         <div  class="me-detail-content__swiper--video"  v-for="(item, index) in videos" :key="'video' + index"  @click="handlerPlayVideo(item.path,item.name)">
           <i class="me-icon-playVideo"></i>
            <video>            
            <source :src="item.path" />
          </video>
         </div>
        </div>
        <van-image-preview
          v-model="showImg"
          :images="images"
          @change="onChange"
          :startPosition="curImgIndex"
          class="me-detail-content__imgpreview"
        >
          <!-- <template v-slot:index>第{{ curImgIndex }}页</template> -->
        </van-image-preview>
      </div>
      <div :class="{ 'me-detail-content__desc': true, more: isShowMore === 2 }">
        <div class="content" ref="textContent">
          <DisplayComponent v-for="(item,index) in info.detailArr" :key="index" :item="item"></DisplayComponent>
        </div>
      </div>
      <ul class="me-detail-content__files" v-if="files.length">
        <li v-for="(file, index) in files" :key="index" @click="downloadFile(file.path, file.name)">
          <img
            :src="
              formartfileIcon(
                file.name.slice(file.name.indexOf('.') + 1, file.name.length)
              )
            "
            alt
          />
          <span>{{ file.name }}</span>
        </li>
      </ul>
      <Zan :id="id" :count="info.like_count" :likeStatus="info.like_id ? true : false" :type="handlerTransType()" />
      <Comments
        :id="id"
        :count="info.reviewer_count"
        :type="type"
        ref="comments"
        @handlerReplay="handlerEmitReplay"
        @replayChild="handlerEmitReplayChild"
      />
      <Recommend :id="id" :type="type" v-if="type !== 'service'"/>
      <PostRecommend :postInfo="info" v-if="type == 'service' && info.gigId"></PostRecommend>
    </div>
    <div class="chatBtn" v-if="!showReplay">
      <div
        class="btn"
        @click="jumpChatWithPage(info)"
      >
        <i class="me-icon-chat-dot"></i>
      </div>
    </div>
    <myShare :show="shareStatus" @close="handlerChangeStatus" :data="shareData" />
    <div class="replayInput" v-if="showReplay">
      <input v-model="replay" ref="replayInput" id="replayInput" v-focus="showReplay" />
      <van-button
        type="primary"
        icon="success"
        @click="isReplayChild ? handlerReplayChild() : handlerReplayFunc()"
      ></van-button>
    </div>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import Author from "@/components/business/author";
import Zan from "@/components/business/h5/zan";
import myShare from "@/components/common/share";
import Comments from "@/components/business/h5/comments";
import Recommend from "@/components/business/h5/recommend";
import PostRecommend from "@/components/business/newVersion/common/recommendList/postList";
import DisplayComponent from "@/components/business/newVersion/common/displayComponent"
import {
  getServiceDetail,
  getCompanyDetail,
  replayViews,
  getPostInformation,
} from "@/api/webApi";
import { formartTags, formartfileIcon, formartImageUrl } from "@/utils/help";
import { downloadFile } from "@/utils";
import { Base64 } from "js-base64";
export default {
  name: "detail",
  data() {
    return {
      id: "",
      info: {},
      loading: false,
      tags: [],
      images: [],
      videos:[],
      files: [],
      isShowMore: 1,
      showImg: false,
      curImgIndex: 0,
      likePeople: [],
      type: "service",
      language: "1",
      shareStatus: false,
      shareData: {},
      showReplay: false,
      replay: "",
      isReplayChild: false,
      relpalChildObj: {},
      typeNum:1
    };
  },
  components: { Header, Author, Comments, Zan, Recommend, myShare ,DisplayComponent, PostRecommend },
  watch: {
    "$route.params.id": {
      handler(val) {
        this.id = val;
        window.location.reload();
        // this.handlerGetDetail();
      },
      deep: true
    },
    info: {
      handler(val) {
        if (Object.keys(val).length)
          this.shareData = {
            title: val.i_can_do,
            desc: val.detail,
            url: `${window.location.href}`
          };
      },
      deep: true
    }
  },
  created() {
    try{
      if(this.$route.params.id.indexOf("pai_") !== -1){
        this.id = Base64.decode(this.$route.params.id.replace("pai_", ""));
      }else {
        this.id = this.$route.params.id;
      }
    } catch (e) {}
    this.type = "service";
    this.language = this.$route.params.language || 1;
    localStorage.setItem("langCode",this.language);
    if (this.type === "enterprise") {
      this.handlerGetCompanyDetail();
    } else {
      this.handlerGetDetail();
    }
  },
  methods: {
    downloadFile,
    formartfileIcon,
    jumpChatWithPage(info){
      this.$router.push({
        name:'chatWith',
        params:{
          id:"pai_" + Base64.encode(info.provider_id),
          name:info.name,
          type:'chat'
        }
      })
    },
    handlerPlayVideo(url,name){            
        window.open(url,name)
    },
    handlerEmitReplay() {
      this.showReplay = !this.showReplay;
      this.isReplayChild = false;
      this.relpalChildObj = {};
    },
    handlerEmitReplayChild(obj) {
      this.showReplay = !this.showReplay;
      this.isReplayChild = true;
      this.relpalChildObj = obj;
    },
    async handlerReplayChild() {
      const loginUser = this.$store.state.User.userinfo;
      if (loginUser && Object.keys(loginUser).length && loginUser.id) {
        const params = {
          ...this.relpalChildObj,
          reply: this.replay,
          userId: loginUser.id,
          token: loginUser.token,
          language: this.language
        };
        const result = await replayViews(params).catch(e => {});
        if (result && result.code == 200) {
          this.handlerEmitReplay();
          this.replay = "";
          this.$refs.comments.getComments();
        }
      } else {
        this.$router.push({
          name: "login",
          query: {
            redirect: this.$route.name,
            ...this.$route.params
          }
        });
      }
    },
    handlerTransType(){
      let type = 1
      switch (this.type) {
        case "service":
          type = 1;
          break;
        case "user":
          type = 3;
          break;
        case "enterprise":
          type = 5;
          break;
        case "group":
          type = 4;
          break;
      }
      return type
    },
    async handlerReplayFunc() {
      let type = this.handlerTransType()
      const params = {
        reply: this.replay,
        language: this.language,
        operationId: this.id,
        operation: type,
        userId: this.$store.state.User.userinfo.id,
        reviewId: ""
      };
      const result = await replayViews(params).catch(e => {});
      if (result && result.data) {
        this.showReplay = false;
        this.replay = "";
        this.$refs.comments.getComments();
      }
    },
    // formartImageUrl,
    handlerChangeStatus() {
      this.shareStatus = !this.shareStatus;
    },

    handlerShowImg(index) {
      this.showImg = true;
      this.curImgIndex = index;
    },
    onChange(index) {
      this.curImgIndex = index;
    },
    handlerDetailShowMore() {
      this.isShowMore = this.isShowMore === 1 ? 2 : 1;
    },
    async handlerGetCompanyDetail() {
      const params = {
        language: this.language,
        enterpriseId: this.id
      };
      const result = await getCompanyDetail(params).catch(e => {});
      if (result && result.data) {
        this.info = this.formartCompany(result.data[0]);
        this.tags = formartTags(this.info.tags);
        this.images = this.info.portfolio.split(",").map(url => {
          return formartImageUrl(url);
        });
        let files = [];
        this.info.mediaDatas.map(item => {
          if (item.type === 4) files.push(item);
        });
        this.files = files;
      }
    },
    formartCompany(data) {
      return {
        name: data.name,
        provider_id: data.enterprise_owner,
        publish_time: data.create_time,
        profile_photo: data.profile_photo,
        reviewer_count: data.reviewer_count,
        like_count: data.like_count,
        mediaDatas: data.mediaDatas,
        detail: data.enterprise_info,
        i_can_do: data.enterprise_name,
        id: data.enterprise_id,
        tags: data.enterprise_tag,
        portfolio: data.enterprise_photos
      };
    },
    // ===========================================================================================================================================
    // 获取帖子详情老接口，先不动;请求完后再请求新的详情接口
    async handlerGetDetail() {
      let params = {
        id: this.id,
        language: this.language,
        type: "0"
      };
      const result = await getServiceDetail(params).catch(e => {});
      if (result && result.data) {
        this.info = result.data[0];
        this.handlerGetDetail_new().then((detailArr)=>{
          this.info.detailArr = detailArr;
          this.tags = formartTags(this.info.tags);
          if(this.info.portfolio !== '') {
            this.images = this.info.portfolio.split(",").map(url => {
              return formartImageUrl(url);
            });
          } else {
            this.images = this.info.mediaDatas.filter(el=>el.type === 3).map(item=>{
              return formartImageUrl(item.path)
            });
          }
          this.videos = this.info.mediaDatas.filter(el=>el.type === 1);
          this.files = this.info.mediaDatas.filter(el=>el.type === 4);
        });
      }
    },
    // 获取帖子详情新接口
    handlerGetDetail_new() {
      return new Promise(async resolve => {
        let result = await getPostInformation(this.id)
        if(result.code == 200) {
          this.info.documentType = result.data.data.documentType;
          this.info.gigId = result.data.data.gigId;
          let detailArr = [];
          try {
            detailArr = JSON.parse(result.data.data.detail)
            detailArr = detailArr.map(item => {
              // 将超链接的内容解析一次
              if(item.key === "url") {
                try {
                  item.content = JSON.parse(item.content)
                } catch (error) {}
              }
              return item;
            })
          } catch (error) {
            detailArr = [{content: result.data.data.detail.replace(/<.*?>/g,"").replace(/&lt;.*?&gt;/g,""),key:"text"}]
          }
          resolve(detailArr)
        } else {
          console.log(result.message)
        }
      })
    },
    
    // =============================================================================================================================================
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

.replayInput {
  width: 100%;
  padding: 0.5rem 0;
  background: #f5f5f5;
  position: fixed;
  border: 0;
  left: 0;
  bottom: 0px;
  display: flex;
  flex-flow: row;

  input {
    border: 1px solid #f5f5f5;
    border-radius: 3rem;
    min-height: 40px;
    margin: 0 0.5rem 0 0.9rem;
    text-indent: 0.6rem;
    flex: 1;
  }

  button {
    height: 40px;
    line-height: 40px;
    margin-right: 0.9rem;
    border-radius: 5rem;
    min-width: 60px;
  }
}

.chatBtn {
  background #f5f5f5;
  color #ffffff;
  font-size 22px;
  position fixed;
  width 100%;
  padding 0.5rem 0;
  bottom 0;
  z-index 10;

  .btn {
    background: #33cc66;
    border-radius: 3rem;
    padding: 0.4rem;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;

    i {
      font-size: 26px;
    }
  }
}

+b(me-detail-content) {
  background: #ffffff;
  margin-top: 54px;
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;
  // height 120vh
  overflow-y scroll

  +e(author) {
    padding: 0.9rem 0.9rem 0;
  }

  +e(title) {
    font-size: 18px;
    text-align: left;
    padding: 0 0.9rem;
  }

  +e(price) {
    color: red;
    text-align: left;
    padding: 0 0.9rem;
    margin-bottom: 0.9rem;
  }

  +e(desc) {
    font-size: 14px;
    color: #666666;
    text-align: left;
    line-height: 24px;
    min-height: 0;
    overflow: hidden;
    position: relative;
    padding: 0 0.9rem;
    transition: all 0.3s;

    .content {
      p {
        margin: 0;
      }
    }

    &.more {
      height: 100% !important;
    }

    [class^='me-icon-'] {
      position: absolute;
      right: 0.9rem;
      bottom: -0.5rem;
      color: #33cc66;
      font-size: 18px;
      background: #ffffff;
      transition: all 0.3s;
    }
  }

  +e(tags) {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 0 0.9rem;

    span {
      margin: 0 0.25rem 0.25rem 0;
    }
  }

  +e(swiper) {
    margin: 0.5rem 0;
    padding: 0 0.9rem;    
    +m(video){
      width 170px
      height 120px
      min-width 170px
      background #e5e5e5
      margin: 0.25rem;
      display flex
      align-items center
      justify-content center
      overflow hidden
      position relative
      video{
        width 170px
        height 120px
      }
      i{
        font-size 40px;
        position absolute
        color rgba(144,144,144,.5)

      }
    }
    +m(imgs) {
      display: flex;
      flex-flow: row;
      overflow-x: scroll;

      img {
        object-fit: cover;
        width: 170px;
        min-width: 170px;
        height: 120px;
        margin: 0.25rem;
      }
    }

    .van-swipe__track {
      .van-swipe-item {
        img {
          width: 100%;
          object-fit: cover;
          height: 180px;
        }
      }
    }
  }

  +e(imgpreview) {
    .van-image-preview__image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover !important;
      }
    }
  }

  +e(files) {
    display: flex;
    flex-flow: column;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin-top: 0.5rem;

    li {
      display: flex;
      flex-flow: row;
      align-items: center;
      font-size: 14px;
      border-bottom: 1px solid #efefef;
      padding: 0.8rem;

      &:last-child {
        border: 0;
      }

      img {
        width: 40px;
        object-fit: cover;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
